import * as React from 'react';
import {FC, useState} from 'react';

import {
    Edit,
    EditProps,
    FieldProps,
    SimpleForm,
    BooleanInput,
    ImageField,
    TextInput,
    ImageInput,
    required,
    ReferenceArrayInput,
    AutocompleteArrayInput
} from 'react-admin';
import {Company} from "../types";
import {onHandleTextInput} from "../utils/textHandling";


export const CompanyTitle: FC<FieldProps<Company>> = ({record}) => {
    return <span> Company {record ? `"${record.commercialName}"` : ''}</span>
}
export const CompanyIndustry = () =>{
    const [filterIndustry, setFilterIndustry] = useState('');
    return <ReferenceArrayInput label="Industries" source="industries" reference="industries"
                         filter={{'q': filterIndustry}}>
        <AutocompleteArrayInput optionText="name" source="industries" onChange={(event) => {
            setFilterIndustry(onHandleTextInput(event))
        }}/>
    </ReferenceArrayInput>
}
const CompanyEdit: FC<EditProps> = props => (
    <Edit title={<CompanyTitle/>} {...props}>
        <SimpleForm>
            <TextInput disabled source="id"/>
            <ImageInput source="logo_url" label="New Logo" accept="image/*">
                <ImageField source="medium"/>
            </ImageInput>

            <TextInput source="commercialName" fullWidth validate={[required()]} label="Commercial Name (Tên sẽ xuất hiện trong KS VNBPTW)"/>
            <CompanyIndustry />
            <TextInput source="legalNameVn" fullWidth label="legalNameVn (Tên Việt sẽ xuất hiện trong ranking trên website)"/>
            <TextInput source="legalNameEn" fullWidth label="legalNameEn  (Tên Anh sẽ xuất hiện trong ranking trên website) "/>
            <TextInput source="sortrankingname" fullWidth label="Sort Ranking Name  (Tên dùng để sort trên website) "/>
            <TextInput source="employees"/>
            <TextInput source="shortDescription" fullWidth multiline/>
            <TextInput source="description" fullWidth multiline/>
            <TextInput source="companyUrl" fullWidth/>
            <TextInput source="portalUrl" fullWidth/>
            <BooleanInput source="valid"/>
        </SimpleForm>
    </Edit>
)

export default CompanyEdit;
