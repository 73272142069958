import * as React from 'react';
import {FC} from 'react';

import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';

const CompanyAwardCreate: FC<CreateProps> = props => (
    <Create {...props}>
        <SimpleForm redirect={"list"}>
            {/*<ReferenceInput label="Award" source="award_id" reference="awardscategory" validate={[required()]}>*/}
            {/*    <SelectInput optionText="name" />*/}
            {/*</ReferenceInput>*/}
            <ReferenceInput
                label="Award"
                source="award_id"
                reference="awardscategory"
                filterToQuery={searchText => ({ name: searchText })}
                validate={[required()]}>
                <AutocompleteInput optionText="name" />

             </ReferenceInput>

            {/*<ReferenceInput label="Company" source="company_id" reference="companies" validate={[required()]}>*/}
            {/*    <SelectInput optionText="commercialName"/>*/}
            {/*</ReferenceInput>*/}

            <ReferenceInput
                label="Company"
                source="company_id"
                reference="companies"
                filterToQuery={searchText => ({ commercialName: searchText })}
                validate={[required()]}>
                <AutocompleteInput optionText="commercialName" />
            </ReferenceInput>

            <TextInput source="year"  validate={[required()]}/>
            <TextInput source="ranking"  validate={[required()]}/>
            <TextInput source="showinranking"  validate={[required()]}/>
        </SimpleForm>
    </Create>
);

export default CompanyAwardCreate;