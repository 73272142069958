import * as React from 'react';
import {FC} from 'react';

import {
    Edit,
    EditProps,
    FieldProps,
    SimpleForm,
    TextInput,
    required,
    ReferenceInput,
    SelectInput
} from 'react-admin';
import {Award} from "../types";


export const AwardName: FC<FieldProps<Award>> = ({record}) => {
    return <span> Award {record ? `"${record.name}"` : ''}</span>
}
const CompanyAwardEdit: FC<EditProps> = props => (
    <Edit title={<AwardName/>} {...props}>
        <SimpleForm>
            <ReferenceInput label="Award" source="award_id" reference="awardscategory">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput label="Company" source="company_id" reference="companies">
                <SelectInput optionText="commercialName"/>
            </ReferenceInput>
            <TextInput source="year"  validate={[required()]}/>
            <TextInput source="ranking"  validate={[required()]}/>
            <TextInput source="showinranking"  validate={[required()]}/>
        </SimpleForm>
    </Edit>
)

export default CompanyAwardEdit;
